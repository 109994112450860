<template>
  <base-modal v-model="isModal">
    <div class="contact-modal">
      <h3 class="h3">Заказать консультацию</h3>
      <div v-if="!successMessage" class="contact-modal__content">
        <base-input
          v-model="consultForm.name"
          label="Имя"
          :error="error.name"
          placeholder="Введите ваше имя"
        ></base-input>

        <base-input label="Телефон" :error="error.phone">
          <input
            v-model="consultForm.phone"
            v-maska:[phoneMask]
            type="tel"
            class="base-form__input"
            :class="{'base-form__input-invalid': error.phone}"
            placeholder="+7 (___)-___-__-__"
            inputmode="tel"
          />
        </base-input>
        <base-input label="Комментарий">
          <textarea
            v-model="consultForm.text"
            class="base-form__textarea"
            placeholder="Введите комментарий"
          ></textarea>
        </base-input>
        <p>
          Менеджер свяжется с вами в течение 5-10 минут, с 09:00 до 18:00 по московскому времени.
        </p>
        <base-button text="Отправить" @click="sendForm()"></base-button>
      </div>
      <p v-else class="base-form__text-success">
        {{ successMessage }}
      </p>
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import {computed, ref, useRoute} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import BaseInput from '@/components/Base/input.vue';
import BaseModal from '@/components/Base/modal.vue';
import {
  useCorrectingForm,
  useIsValidForm,
  useValidateName,
  useValidatePhone,
} from '@/composables/useForm';
import {useContactStore} from '@/stores/contact';
import type {IConsultForm} from '@/types/pages/contact';
import * as masks from '@/utils/masks';

const props = defineProps({
  modelValue: {type: Boolean, required: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const {phoneMask} = masks;

const isModal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const contactStore = useContactStore();
const route = useRoute();

const consultForm = ref<IConsultForm>({
  name: '',
  phone: '',
  text: '',
});

const error = ref<{
  name: string | undefined;
  phone: string | undefined;
}>({
  name: '',
  phone: '',
});

const successMessage = ref('');

const isValidForm = computed(() => useIsValidForm(error.value));

const clearConsultForm = () => {
  consultForm.value.name = '';
  consultForm.value.phone = '';
  consultForm.value.text = '';
};

const clearErrors = () => {
  setTimeout(() => {
    error.value.name = '';
    error.value.phone = '';
  }, 3000);
};

const validateForm = (form: IConsultForm) => {
  error.value.name = useValidateName(form.name);
  error.value.phone = useValidatePhone(form.phone);
  clearErrors();
};

const sendForm = async () => {
  const form = useCorrectingForm(JSON.parse(JSON.stringify(consultForm.value)) as IConsultForm);
  validateForm(form);
  if (isValidForm.value) {
    form.url = route.fullPath;
    await contactStore.sendConsultForm(form);
    clearConsultForm();
    successMessage.value = 'Ожидайте обратный звонок';
  }
};
</script>
